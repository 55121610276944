import './App.css';
import { Routes , Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About'
import Contact from "./Pages/Contact";
import Donate from "./Pages/Donate"; 
import PersonalLoan from './Tools/PersonalLoan';
import Tools from './Pages/Tools';
import LICLoanCalculator from './Tools/LICLoanCalculator';
import LICHomeLoanCalculator from './Tools/HomeLoanCalculator';
import JeevanAnandCalculator from './Tools/JeevanAnandCalculator' 
import JeevanUmangCalculator from './Tools/JeevanUmangCalculator'
import JeevanLabhCalculator from './Tools/JeevanLabhCalculator'
import LICPremiumCalculator from './Tools/LICPremiumCalculator'
import LicSurrenderValueCalculator from './Tools/LicSurrenderValueCalculator'
import LicMaturityCalculator from './Tools/LicMaturityCalculator'
import JeevanSaralMaturityCalculator from './Tools/JeevanSaralMaturityCalculator'
import PensionPlanCalculator from './Tools/PensionPlanCalculator'
import PrivacyAndPolicy from './Pages/PrivacyAndPolicy';
import LICSipCalculator from './Tools/LICSipCalculator';
import Disclaimer from './Pages/Disclaimer'
import TermsAndConditions from './Pages/TermsAndConditions'

function App() {
  return (
  <>
   <Routes>
   <Route path='/' element={<Home />} />
   <Route path='/about-us' element={<About />} />
   <Route path='/contact-us' element={<Contact />} />
   <Route path='/donate-us' element={<Donate />} />
   <Route path='/all-tools' element={<Tools/>}></Route>
   <Route path='/lic-loan-calculator' element={<LICLoanCalculator/>} />
   <Route path='/lic-personal-loan-calculator' element={<PersonalLoan/>} />
   <Route path='/lic-Home-loan-calculator' element={<LICHomeLoanCalculator/>} />
   <Route path='/lic-jeevan-anand-calculator' element={<JeevanAnandCalculator/>} />
   <Route path='/lic-jeevan-umang-calculator' element={<JeevanUmangCalculator/>} />
   <Route path='/lic-jeevan-labh-calculator' element={<JeevanLabhCalculator/>} />
   <Route path='/lic-premium-calculator' element={<LICPremiumCalculator/>} />
   <Route path='/lic-surrender-value-calculator' element={<LicSurrenderValueCalculator/>} />
   <Route path='/lic-maturity-calculator' element={<LicMaturityCalculator/>} />
   <Route path='/lic-jeevan-saral-maturity-calculator' element={<JeevanSaralMaturityCalculator/>} />
   <Route path='/lic-pension-plan-calculator' element={<PensionPlanCalculator/>} />
   <Route path='/lic-sip-calculator' element={<LICSipCalculator/>} />
   <Route path='/privacy-and-policy' element={<PrivacyAndPolicy/>} />
   <Route path='/disclaimer' element={<Disclaimer/>} />
   <Route path='/terms-and-conditions' element={<TermsAndConditions/>} />

   </Routes>
  </>
  );
}

export default App;
