import React ,{useEffect}  from 'react'
import Layout from '../Componets/Layout'
import { Link } from 'react-router-dom'
import '../Componets/Header.css'
import './About.css'



const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  return (
    <Layout     pageTitle= "LicCalculator All in One -  LicCalculators"
    metaDescription="Welcome to LICCalculators. Explore a variety of tools to help you with LIC calculations and more."
    metaKeywords="LIC calculators, financial tools, LIC loan calculator, LIC premium calculator"
    canonicalUrl="https://liccalculators.com/"
    robotsContent="index, follow"
    ogTitle="LICCalculator All in One"
    ogDescription="Explore our range of LIC calculators and financial tools. Get accurate results and manage your LIC investments effectively."
    ogUrl="https://liccalculators.com/"
    ogImage="https://liccalculators.com/img/new_logo.png"
    schemaData={{
      "@context": "https://schema.org",
      "@type": "WebSite",
      "url": "https://liccalculators.com/",
      "name": "LICCalculators",
      "description": "Explore a variety of LIC calculators and tools.",
      "publisher": {
        "@type": "Organization",
        "name": "LICCalculators",
        "logo": {
          "@type": "ImageObject",
          "url": "https://liccalculators.com/img/new_logo.png"
        }
      }
    }}
>
  
    <div className="container-fluid px-4 py-5 my-5 text-center curved">
	<div className="lc-block d-block mx-auto mb-4">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="5em" height="5em" lc-helper="svg-icon" fill="currentColor">
      <path d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zm-16 320H64V128h384v256zm-64-160h-64v-64h64v64zm0-96h-64v-64h64v64zm-96 160h-64v-64h64v64zm0-96h-64v-64h64v64zm-96 160h-64v-64h64v64zm0-96h-64v-64h64v64zm-96 160H64v-64h64v64zm0-96H64v-64h64v64zm0-96H64v-64h64v64zm-32-96h-32V96h32v32zm0 64h-32v-32h32v32z"></path>
    </svg>



	</div>
	<div className="lc-block">
		<div editable="rich">

			<h2 className="display-5 fw-bold">Welcome to Our <span style={{color:'#26B75D'}}>Lic Calculators Tools </span></h2>

		</div>
	</div>
	<div className="lc-block col-lg-6 mx-auto mb-4">
		<div editable="rich">

			<p className="lead ">Planning your financial future and insurance needs has never been easier. At LIC Calculator Tools, we provide a suite of powerful and easy-to-use calculators designed to help you make informed decisions about your Life Insurance Corporation (LIC) policies.</p>

		</div>
	</div>

	<div className="lc-block d-grid gap-2 d-sm-flex justify-content-sm-center"> <Link className="btn btn-success btn-lg px-4 gap-3" to="/all-tools" role="button">Try Tools</Link>
		<Link className="btn btn-outline-success btn-lg px-4" to="/contact-us" role="button">Contact Us</Link>
	</div>
</div>

<div className='container-fluid'>

</div>
 
 {/* tools section  */}

 <div className='container-fluid' style={{marginTop:'40px'}}>
<p className='text-center' style={{fontSize:'30px',marginBottom:'30px'}}>Explore Our Range of Calculators</p>
 <div className="container">
 
  <div className="row ">
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  {/* <img className="card-img-top" src="/img/SIP (1).webp" height={'200vh'} alt="SIP Calculator img"/> */}
  <div className="card-body">
    <h5 className="card-title"><b>LIC Personal Loan Calculator</b></h5>
    <p className="card-text">Estimates monthly payments and total interest for personal loans from LIC. </p>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>
  {/* <img class="card-img-top" src="/img/FD.webp"  height={'200vh'} alt="FD Calculator img" /> */}
  <div class="card-body">
    <h5 class="card-title"><b>LIC Home Loan Calculator</b></h5>
    <p class="card-text">Designed to calculate EMI and total interest for home loans from LIC, providing clear insights for better financial planning. </p>

  </div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>
  {/* <img class="card-img-top" src="/img/SWP.webp"  height={'200vh'} alt="SWP Calculator img"/> */}
  <div class="card-body">
    <h5 class="card-title"><b>LIC Loan Calculator</b></h5>
    <p class="card-text"> A versatile tool to compute EMIs and interest for various types of loans from LIC, aiding in effective loan management.</p>

  </div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>
  {/* <img class="card-img-top" src="/img/SI.webp"  height={'200vh'} alt="Simple Interest Calculator img" /> */}
  <div class="card-body">
    <h5 class="card-title"><b>LIC Jeevan Anand Calculator</b></h5>
    <p class="card-text">Calculates premiums for LIC's Jeevan Anand policy and visualizes yearly premiums.</p>

  </div>
</div>
    </div>
  </div>

  {/* second rows  */}

  <div className="row mt-5">
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  {/* <img className="card-img-top" src="/img/MRF.webp" height={'200vh'}   alt="Mutual Fund Return Calculator img" /> */}
  <div className="card-body">
    <h5 className="card-title"><b>Jeevan Labh Calculator</b></h5>
    <p className="card-text"> The Jeevan Labh Calculator helps calculate premiums, maturity amounts, and benefits for LIC's Jeevan Labh plan.</p>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  {/* <img className="card-img-top" src="/img/PPF.webp" height={'200vh'}  alt="PPF Calculator img" /> */}
  <div className="card-body">
    <h5 className="card-title"><b>Jeevan Saral Maturity Calculator</b></h5>
    <p className="card-text"> Jeevan Saral Maturity Calculator calculates maturity benefits from premiums, policy term, and age.</p>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  {/* <img className="card-img-top" src="/img/SSY.webp" height={'200vh'}  alt="Sukanya Samriddhi Yojana Calculator img" /> */}
  <div className="card-body">
    <h5 className="card-title"><b>Jeevan Umang Calculator</b></h5>
    <p className="card-text">The Jeevan Umang Calculator estimates premiums, survival benefits, and maturity for LIC’s Jeevan Umang policy.</p>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  {/* <img className="card-img-top" src="/img/RD.webp" height={'200vh'}  alt="RD Calculator img"/> */}
  <div className="card-body">
    <h5 className="card-title"><b>Lic Maturity Calculator</b></h5>
    <p className="card-text">The LIC Maturity Calculator computes maturity values, bonuses, and final payouts for various LIC policies.</p>
  </div>
</div>
 </div>

  </div>
  
  
</div>
</div>
 

  

  <div className="container">
    <div className="inline-content">
    <div>
    <h4>Get in touch with us!</h4>
    <p>Have questions or need assistance? We're here to help.</p>
    </div>
          <button className="btn btn-success">
      <Link className='text-dec' to='/contact-us' style={{ textDecoration: 'none', color: 'white' }}>Contact Us</Link>
    </button>
    </div>
  </div>
    </Layout>
  )
}

export default Home
